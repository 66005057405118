<template>
  <div>ReAuthenticate</div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ReAuthenticate',
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logout'])
  },
  mounted () {
    if (this.user !== null) {
      this.logout().then(() => {
        localStorage.clear()
        localStorage.setItem('environment', process.env.VUE_APP_ENV)
        this.$router.push({ name: 'Login', query: { username: this.$route.query.username } })
      }).catch(() => {
        localStorage.clear()
        localStorage.setItem('environment', process.env.VUE_APP_ENV)
        this.$router.push({ name: 'Login', query: { username: this.$route.query.username } })
      })
    } else {
      this.$router.push({ name: 'Login', query: { username: this.$route.query.username } })
    }
  }
}
</script>
